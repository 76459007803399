// **  Initial State
const initialState = {
  userData: {},
  merchant:{},
  loanlist:[]
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADDLOAN':
      let data=state.loanlist
      data=[action.payload,...data,]
        return {...state,loanlist:data  }
    case 'LOANLIST':
      if(action.payload!=undefined){
        return { ...state, loanlist: action.payload }
      }
    case 'GETMERCHANT':
      return { ...state, merchant: action.payload }
    case 'LOGIN':
      return { ...state, userData: action.data }
    case 'LOGOUT':
      return { ...state, userData: {} }
    default:
      return state
  }
}

export default authReducer
