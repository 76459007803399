// ** Logo
import logo from "@src/assets/images/logo/new-logo.png"

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner vh-100'>
      <img
        className='fallback-logo'
        src='https://zotto.io/wp-content/uploads/2021/09/cropped-1@2x-32x32.png'
        alt='logo'
        style={{ marginLeft: "4%", height: "40px", width: "40px" }}
      />
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
